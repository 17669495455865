<template>
  <v-card elevation="0" v-if="nbQueries > 0">
    <v-card-title>
      <span>{{ nbQueriesText }}</span>
      <v-spacer></v-spacer>
      <v-btn outlined color="success" class="mr-2" @click="$emit('click')">{{
        $t("keepValue")
      }}</v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: ["pageItem"],
  computed: {
    notClosedQueries() {
      return this.currentInterview
        ?.availableProcesses(this.currentWorkflow)
        ?.filter(
          p =>
            p.action == "query" &&
            p.pageItem == this.pageItem &&
            !p.isClosed &&
            !p.isCanceled
        );
    },
    nbQueries() {
      return this.notClosedQueries.length;
    },
    nbQueriesText() {
      return `${this.$t("nbQueries")} ${this.nbQueries}`;
    }
  },
  i18n: {
    messages: {
      en: {
        keepValue: "Keep current value",
        nbQueries: "Number of opened queries : "
      },
      fr: {
        keepValue: "Garder la valeur actuelle",
        nbQueries: "Nombre de query ouvertes : "
      }
    }
  }
};
</script>
