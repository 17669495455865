<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>{{ $t("justification") }}</v-card-title>
      <v-card-text>
        <v-textarea
          :label="$t('yourAnswer')"
          outlined
          v-model="answer"
        ></v-textarea>
        <item-messages
          :messages="messages"
          @change="
            setJustification($event);
            onMessagesEvent('changemessage', $event);
          "
        ></item-messages>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="createAnswer()" :disabled="!answer">
          {{ $t("answer") }}
        </v-btn>
        <v-btn
          v-if="this.openedQueries"
          text
          color="danger"
          @click="cancelAnswer()"
        >
          {{ $t("action.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isAcknowledged, messageEntries } from "spiral";
import ItemMessages from "../form/ItemMessages";
import item from "../../mixin/item";

export default {
  props: ["pageItem", "openedQueries"],
  data() {
    return {
      showDialog: this.openedQueries,
      answer: undefined,
      replyFunc: undefined
    };
  },
  computed: {
    item() {
      return this.currentInterview.items.find(i => i.pageItem == this.pageItem);
    },
    messages() {
      return this.item?.messages;
    },
    queries() {
      return this.currentInterview
        ?.availableProcesses(this.currentWorkflow)
        ?.filter(p => p.action == "query" && p.pageItem == this.pageItem)
        ?.filter(p =>
          this.openedQueries ? !p.isClosed && !p.isCanceled : p.isPending
        );
    },
    inputs() {
      return this.currentInterview
        ?.availableProcesses(this.currentWorkflow)
        ?.filter(p => p.action == "input" && p.pageItem == this.pageItem)
        ?.filter(p => p.isPending);
    }
  },
  methods: {
    async createAnswer() {
      const replied = this.replyFunc();
      await this.pushSaveProcesses(replied)
        .then(() => {
          this.cancelAnswer();
        })
        .catch(err => console.error(err));
    },
    answerQueries() {
      return this.queries.map(q =>
        q.reply(this.answer, this.currentUser.userid)
      );
    },
    answerInputs() {
      return this.inputs.map(i => i.justify(this.answer));
    },
    cancelAnswer() {
      this.showDialog = false;
      this.answer = undefined;
      this.$emit("close");
    },
    setJustification(messages) {
      for (const [rule, msg, ack] of messageEntries(messages))
        if (ack && !isAcknowledged(this.messages, rule)) {
          const prev = this.answer ? `${this.answer}\n` : "";
          this.answer = `${prev}"${msg}": ${this.$t("acknowledged")}`;
        }
    }
  },
  watch: {
    queries(val) {
      if (val.length > 0) {
        this.replyFunc = this.answerQueries;
        this.showDialog = true;
      }
    },
    inputs(val) {
      if (val.length > 0) {
        this.replyFunc = this.answerInputs;
        this.showDialog = true;
      }
    }
  },
  components: {
    ItemMessages
  },
  mixins: [item],
  i18n: {
    messages: {
      en: {
        justification: "Justification",
        answer: "Answer",
        yourAnswer: "Your answer",
        acknowleged: "acknowledged"
      },
      fr: {
        justification: "Justification",
        answer: "Répondre",
        yourAnswer: "Votre réponse",
        acknowleged: "acquitté"
      }
    }
  }
};
</script>
