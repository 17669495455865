<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <div>Queries</div>
          <v-spacer></v-spacer>
          <v-btn v-if="canQuery" text color="info" @click="initQuery()">{{
            $t("newQuery")
          }}</v-btn>
        </v-card-title>
        <v-card-text>
          <query-list
            :pageItem="pageItem"
            @changemessage="$emit('changemessage', $event)"
          ></query-list>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="startNewQuery"
        persistent
        width="500px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title>{{ $t("newQuery") }}</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="queryQuestion"
              outlined
              :label="$t('yourQuestion')"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="text-right col">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="createQuery()">{{
              $t("createQuery")
            }}</v-btn>
            <v-btn text color="danger" @click="cancelQuery()">{{
              $t("action.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { Query } from "spiral";
import tag from "../../mixin/tag";
import QueryList from "./QueryList.vue";
import { mapGetters } from "vuex";

export default {
  props: ["pageItem"],
  data() {
    return {
      startNewQuery: false,
      queryQuestion: undefined
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    canQuery() {
      return this.authorizationManager.canOpenQuery(this.currentInterview);
    },
    canAnswer() {
      return this.isInRole("investigator", "developer");
    }
  },
  methods: {
    initQuery() {
      this.startNewQuery = true;
    },
    async createQuery() {
      if (this.startNewQuery) {
        this.startNewQuery = false;
        if (this.queryQuestion && this.canQuery)
          await this.pushSaveProcesses([
            new Query(this.queryQuestion, this.currentUser?.userid, {
              pageItem: this.pageItem,
              tag: this.tagName
            })
          ]);
        this.queryQuestion = undefined;
      }
    },
    cancelQuery() {
      this.startNewQuery = false;
      this.queryQuestion = undefined;
    }
  },
  i18n: {
    messages: {
      en: {
        newQuery: "New Query",
        yourQuestion: "Your question : ",
        createQuery: "Create Query"
      },
      fr: {
        newQuery: "Nouvelle Query",
        yourQuestion: "Votre question : ",
        createQuery: "Créer la query"
      }
    }
  },
  mixins: [tag],
  components: {
    QueryList
  }
};
</script>
