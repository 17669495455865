<template>
  <div>
    <div class="mb-3">
      <query-synthesis
        :pageItem="pageItem"
        v-if="canAnswer"
        @click="initAnswer()"
      ></query-synthesis>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="date"
      :sort-desc="true"
      id="queries-table"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-icon color="success" v-if="item.closed">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else-if="item.canceled">mdi-close-circle</v-icon>
        <v-icon color="warning" v-else>mdi-help-circle</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="canCancel(item)"
          icon
          color="danger"
          @click="cancelQuery(item.query)"
        >
          <v-icon v-if="canCancel(item)">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <justification-dialog
      :pageItem="pageItem"
      :openedQueries="showJustification"
      @changemessage="$emit('changemessage', $event)"
      @close="showJustification = false"
    ></justification-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import JustificationDialog from "./JustificationDialog.vue";
import QuerySynthesis from "./QuerySynthesis.vue";
export default {
  components: { QuerySynthesis, JustificationDialog },
  props: ["pageItem"],
  data() {
    return {
      showJustification: false,
      users: []
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    items() {
      return this.currentInterview
        ?.availableProcesses(this.currentWorkflow)
        ?.filter(p => p.action == "query" && p.pageItem == this.pageItem)
        ?.map(p => ({
          query: p,
          creatorId: p.creatorId,
          date: this.getLabel("datetime", {}, p.date, this.$i18n),
          question: p.question,
          answer: p.answer,
          closed: p.isClosed,
          canceled: p.isCanceled,
          role: p.tag
        }));
    },
    headers() {
      return [
        { text: this.$t("date"), value: "date", width: "13rem" },
        { text: this.$t("creator"), value: "creatorId" },
        { text: this.$t("role"), value: "role" },
        { text: this.$t("status"), value: "status" },
        { text: this.$t("question"), value: "question", class: "mw-15" },
        { text: this.$t("justification"), value: "answer" },
        { text: "", value: "actions" }
      ];
    },
    canAnswer() {
      return this.isInRole("investigator", "developer");
    }
  },
  methods: {
    canCancel(item) {
      const queryUser = this.users.find(u => u.userid == item.creatorId);
      return this.isInRole(queryUser?.role) && !item.canceled && !item.closed;
    },
    async cancelQuery(query) {
      const canceled = query.cancel();
      await this.pushSaveProcesses([canceled]).catch(err => console.log(err));
    },
    initAnswer() {
      this.showJustification = true;
    }
  },
  async created() {
    this.users = await this.drivers.userDriver.getAll(this.currentStudy);
  },
  i18n: {
    messages: {
      en: {
        justification: "Justification",
        answerQuery: "Answer",
        yourAnswer: "Your answer",
        creator: "Created by",
        question: "Question",
        date: "Date",
        status: "Query Status",
        role: "Role"
      },
      fr: {
        justification: "Justification",
        answerQuery: "Répondre",
        yourAnswer: "Votre réponse",
        creator: "Créé par",
        question: "Question",
        date: "Date",
        closed: "Status de Query",
        role: "Role"
      }
    }
  }
};
</script>
